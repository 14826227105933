import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoachSelectors } from '@app/state/coach/coach.selectors';
import { selectToken } from '@app/state/login/login.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggerService } from '@app/services/logger.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  public constructor(
    private store: Store,
    private logger: LoggerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }

    // Check if we're in an active impersonation session
    const isImpersonating = window.sessionStorage.getItem('activeImpersonationSession') === 'true';
    const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');

    // If we're in impersonation mode, use the token with loggedasuser header
    if (isImpersonating && loggedAsClientStr) {
      try {
        // Get the client ID from the impersonation data
        const clientData = JSON.parse(loggedAsClientStr);
        const clientId = clientData.clientId;

        // Get the token from session storage
        const sessionToken = window.sessionStorage.getItem('token');

        if (sessionToken && clientId) {
          this.logger.info(`Using impersonation mode for API request to: ${req.url}`);

          // Create request with token and loggedasuser header
          const finalReq = req.clone({
            setHeaders: {
              ['Authorization']: sessionToken,
              ['loggedasuser']: clientId
            }
          });

          // Check if wizeFiID matches client ID during API call
          const currentWizeFiID = window.sessionStorage.getItem('wizeFiID');
          if (currentWizeFiID !== clientId) {
            this.logger.warn(`wizeFiID changed during API call, resetting to: ${clientId}`);
            window.sessionStorage.setItem('wizeFiID', clientId);
          }

          this.logger.info(`Sending request with impersonation headers for client: ${clientData.firstName} ${clientData.lastName}`);
          return next.handle(finalReq);
        } else {
          this.logger.error(`Missing token or clientId for impersonation request. Token exists: ${!!sessionToken}, ClientId exists: ${!!clientId}`);
        }
      } catch (error) {
        this.logger.error('Error processing impersonation headers:', error);
      }
    }

    // Normal flow (not in impersonation mode)
    return combineLatest([this.store.select(selectToken), this.store.select(CoachSelectors.loggedAsClient)]).pipe(
      take(1),
      switchMap(([token, loggedAsClient]) => {
        if (!token) {
          return next.handle(req);
        }
        const withAuth = req.clone({ setHeaders: { ['Authorization']: token } });
        const finalReq = loggedAsClient ? withAuth.clone({ setHeaders: { ['loggedasuser']: loggedAsClient.clientId } }) : withAuth;
        return next.handle(finalReq);
      })
    );
  }
}
