import { createReducer, on } from '@ngrx/store';
import { StrategiesActions } from './strategies.actions';
import { StrategiesState } from './strategies.state';
import { switchToFailure } from '../reducer-utils';
import { ChangePriorityResponse, Strategy } from '@wizefi/entities';
import { StrategiesFilter } from './strategies.type';

export const initialState: StrategiesState = {
  status: 'pending',
  priorityStatus: 'pending',
  completeStepStatus: 'pending',
  strategies: [],
  filteredStrategies: [],
  categories: [],
  priorities: [],
  difficulties: [],
  totalSavings: 0,
  selectedStrategy: null
};

const filterStrategies = (state: StrategiesState, props: StrategiesFilter): StrategiesState => {
  // shallow copy due to array sort method in use
  let filteredStrategies = [...state.strategies];

  // filter by property
  filteredStrategies =
    props.priorityFilter === 0 ? filteredStrategies : filteredStrategies.filter(strategy => strategy.priority === props.priorityFilter);

  // filter by category
  filteredStrategies =
    props.categoryFilter === 0 ? filteredStrategies : filteredStrategies.filter(strategy => strategy.category === props.categoryFilter);

  // filter by subcategory
  filteredStrategies =
    props.subcategoryFilter === 0 ? filteredStrategies : filteredStrategies.filter(strategy => strategy.subcategory === props.subcategoryFilter);

  // filter by difficulty
  filteredStrategies =
    props.difficultyFilter === 0 ? filteredStrategies : filteredStrategies.filter(strategy => strategy.difficulty === props.difficultyFilter);

  const name = (props.nameFilter || '').trim().toLocaleLowerCase();

  // filter by name
  filteredStrategies = name === '' ? filteredStrategies : filteredStrategies.filter(strategy => strategy.name.toLocaleLowerCase().indexOf(name) > -1);

  const estimatedSavingsOp = props.estimatedSavingOpFilter;
  const estimatedSavingsVal = props.estimatedSavingValFilter;

  // filter by estimated savings value using operator
  if (estimatedSavingsOp && estimatedSavingsVal) {
    filteredStrategies = filteredStrategies.filter(
      s =>
        (estimatedSavingsOp === '>=' && s.estimatedSavings >= estimatedSavingsVal) ||
        (estimatedSavingsOp === '<=' && s.estimatedSavings <= estimatedSavingsVal) ||
        (estimatedSavingsOp === '=' && s.estimatedSavings === estimatedSavingsVal)
    );
  }

  const actualSavingsOp = props.actualSavingOpFilter;
  const actualSavingsVal = props.actualSavingValFilter;

  if (actualSavingsOp && actualSavingsVal) {
    filteredStrategies = filteredStrategies.filter(
      s =>
        (actualSavingsOp === '>=' && s.actualSavings && s.actualSavings >= actualSavingsVal) ||
        (actualSavingsOp === '<=' && s.actualSavings && s.actualSavings <= actualSavingsVal) ||
        (actualSavingsOp === '=' && s.actualSavings === actualSavingsVal)
    );
  }

  const weeklyEffortOp = props.weeklyEffortOpFilter;
  const weeklyEffortMeasure = props.weeklyEffortMeasureFilter;
  const weeklyEffortVal = weeklyEffortMeasure === 'h' ? 60 * (props.weeklyEffortValFilter || 0) : props.weeklyEffortValFilter;

  if (weeklyEffortOp && weeklyEffortVal && weeklyEffortMeasure) {
    filteredStrategies = filteredStrategies.filter(
      s =>
        (weeklyEffortOp === '>=' && s.weeklyEffortInMins >= weeklyEffortVal) ||
        (weeklyEffortOp === '<=' && s.weeklyEffortInMins <= weeklyEffortVal) ||
        (weeklyEffortOp === '=' && s.weeklyEffortInMins === weeklyEffortVal)
    );
  }

  const progressVal = props.progressValFilter;

  if (progressVal) {
    filteredStrategies = filteredStrategies.filter(s => Number(s.progress) <= progressVal);
  }

  const sortProperty = props.sortProperty;
  const sortDirection = props.sortDirection;

  if (sortProperty && sortDirection) {
    filteredStrategies.sort((a: Strategy, b: Strategy) => {
      if ((a[sortProperty as keyof Strategy] || 1) < (b[sortProperty as keyof Strategy] || 1)) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if ((a[sortProperty as keyof Strategy] || 1) > (b[sortProperty as keyof Strategy] || 1)) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  return { ...state, filteredStrategies };
};

const iterateStrategiesDuePriorityChange = (strategies: Strategy[], props: ChangePriorityResponse) => {
  const strategyRes = props;
  return strategies.map(strategy => {
    if (strategy.dictionaryId === strategyRes.dictionaryId) {
      return {
        ...strategy,
        id: strategyRes.id,
        priority: strategyRes.priority
      };
    }
    return strategy;
  });
};

const changePriority = (state: StrategiesState, props: ChangePriorityResponse): StrategiesState => ({
  ...state,
  selectedStrategy: {
    ...state.selectedStrategy,
    id: props.id
  } as Strategy | null,
  strategies: iterateStrategiesDuePriorityChange(state.strategies, props),
  filteredStrategies: iterateStrategiesDuePriorityChange(state.filteredStrategies, props),
  priorityStatus: 'success'
});

const changePriorityRequested = (state: StrategiesState): StrategiesState => ({
  ...state,
  priorityStatus: 'loading'
});

const iterateStrategiesDueCompleteStep = (strategies: Strategy[], props: Strategy) => {
  const strategyRes = props;
  return strategies.map(strategy => {
    if (strategy.dictionaryId === strategyRes.dictionaryId) {
      const newSteps = strategy.steps?.map(step => {
        const dbStep = strategyRes.steps?.find(dbStepI => step.id === dbStepI.id);
        return {
          ...step,
          ...dbStep
        };
      });
      const numberCompletedSteps =
        newSteps?.reduce((acc, step) => {
          if (step.isDone) {
            acc = acc + 1;
          }
          return acc;
        }, 0) || 0;
      const progress = Math.floor((numberCompletedSteps / (newSteps?.length || 1)) * 100);
      return {
        ...strategy,
        progress,
        id: strategyRes.id,
        steps: newSteps,
        actualSavings: strategyRes.actualSavings
      };
    }
    return strategy;
  });
};

const completeStep = (state: StrategiesState, props: Strategy): StrategiesState => {
  const strategies = iterateStrategiesDueCompleteStep(state.strategies, props);
  const totalSavings = strategies.reduce((acc, strategy) => acc + (strategy.actualSavings || 0), 0);
  // now it has id, not just dictionaryId
  const selectedStrategy = strategies.find(strategy => strategy.dictionaryId === props.dictionaryId) as Strategy;
  return {
    ...state,
    selectedStrategy,
    strategies,
    totalSavings,
    filteredStrategies: iterateStrategiesDueCompleteStep(state.filteredStrategies, props),
    completeStepStatus: 'success'
  };
};

const completeStepRequested = (state: StrategiesState): StrategiesState => ({
  ...state,
  completeStepStatus: 'loading'
});

const selectStrategy = (state: StrategiesState, props: Strategy): StrategiesState => ({
  ...state,
  selectedStrategy: props
});

export const strategiesReducer = createReducer(
  initialState,
  on(StrategiesActions.fetchStrategies, (state): StrategiesState => ({ ...state, status: 'loading' })),
  on(
    StrategiesActions.fetchStrategiesSuccessful,
    (state, props): StrategiesState => ({
      ...state,
      ...props.data,
      filteredStrategies: props.data.strategies,
      status: 'success'
    })
  ),
  on(StrategiesActions.fetchStrategiesFailed, switchToFailure),
  on(StrategiesActions.filterStrategies, filterStrategies),
  on(StrategiesActions.upsertPriorityRequested, changePriorityRequested),
  on(StrategiesActions.upsertPrioritySuccessful, changePriority),
  on(StrategiesActions.upsertPriorityFailed, switchToFailure),
  on(StrategiesActions.completeStepRequested, completeStepRequested),
  on(StrategiesActions.completeStepSuccessful, completeStep),
  on(StrategiesActions.selectStrategy, selectStrategy),
  on(StrategiesActions.finishStrategyRequested, completeStepRequested),
  on(StrategiesActions.openStrategySuccessful, changePriority)
);
