/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from '@wizefi/entities';
import {
  START_HERE_PATH,
  LOGIN_PATH,
  SETUP_ACCOUNTS_PATH,
  SETUP_GOALS_PATH,
  SETUP_INCOME_PATH,
  SETUP_PATH,
  SETUP_PROJECTIONS_PATH
} from '@app/modules/route-paths';
import { LoginService } from '@app/services/login.service';
import { LoggerService } from '@app/services/logger.service';
import { MessageService } from '@app/services/message/message.service';
import { SessionManagerService } from '@app/services/session-manager.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap, filter } from 'rxjs/operators';
import { monthSelected } from '../selected-month/selected-month.actions';
import { AccountActions } from '../account/account.actions';
import { LoginActions } from './login.actions';
import { logoutRequested } from '../screen.actions';
import { CachedProjectionsService } from '@app/services/cached-projections.service';
import { HttpErrorResponse } from '@angular/common/http';
import { stopLoadingTransactions, syncTransactionsRequested } from '../transaction/transaction.actions';

// Define action type for post login actions
const POST_LOGIN_ACTIONS = '[Login] Post Login Actions';

// Define interface for post login actions payload
interface PostLoginActionsPayload extends Action {
  readonly type: typeof POST_LOGIN_ACTIONS;
  readonly actions: Action[];
}

@Injectable()
export class LoginEffects {
  loginRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.loginRequested),
      switchMap(({ email, password }) =>
        this.loginService.login(email, password, new Date().toISOString().substring(0, 7)).pipe(
          map(result => {
            if (result?.status === 'SubscriptionExpired') {
              this.messageService.info(
                `
                Please contact WizeFisupport@bluprintworldwide.com regarding your account access.
                `,
                500000
              );
              this.router.navigate(['/login']);
              return LoginActions.loginFailed({ err: 'Your enterprise subscription has expired.' });
            }

            if (result?.status === 'DeactivatedEnterpriseUser') {
              this.messageService.info(
                `
                Your subscription is no longer active.
                Please contact BluPrint (WizeFisupport@bluprintworldwide.com) or WizeFi
                (support@wizefi.com) to reactivate your subscription.
                `,
                500000
              );
              this.router.navigate(['/login']);
              return LoginActions.loginFailed({ err: 'Enterprise user deactivated.' });
            }

            return LoginActions.loginSuccessful(result);
          }),
          tap(() => window.localStorage.setItem('email', email)),
          catchError(err => {
            if (err.status === 401) {
              this.messageService.error('Invalid user or password.');
            } else {
              this.messageService.error('An error occurred. Please try again.');
            }
            return of(LoginActions.loginFailed({ err: err.message }));
          })
        )
      )
    );
  });

  loginSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.loginSuccessful),
      switchMap(async ({ id, token, userData, braintreeData }) => {
        try {
          // Check if this is a coach logging in during an impersonation session
          const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
          const isCoachReturningFromImpersonation = userData?.isCoach === true && loggedAsClientStr;

          if (isCoachReturningFromImpersonation) {
            // Coach is logging in while there's an active impersonation session
            // This likely means they're trying to return to their coach account
            this.logger.info('Coach returning from impersonation session detected');

            // Remove impersonation state but keep coach session if it exists
            window.sessionStorage.removeItem('loggedAsClient');
          }

          // WIZ-2544: Use session manager to clear all session storage with error handling
          // Preserve coach impersonation state if user is a coach and not returning from impersonation
          const preserveCoachImpersonation = userData?.isCoach === true && !isCoachReturningFromImpersonation;
          this.sessionManager.clearSessionData(preserveCoachImpersonation);

          // Store user data in sessionStorage with error handling
          try {
            window.sessionStorage.setItem('wizeFiID', id);
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('userData', JSON.stringify(userData));
            if (braintreeData) {
              window.sessionStorage.setItem('braintreeData', JSON.stringify(braintreeData));
            }
            this.logger.info('User session data stored successfully');
          } catch (error) {
            this.logger.error('Error storing user session data:', error);
            this.messageService.error('Error storing session data. Please try logging in again.', 5000);
          }
        } catch (error) {
          this.logger.error('Unexpected error in login process:', error);
          this.messageService.error('An unexpected error occurred during login. Please try again.', 5000);
        }

        this.cachedProjectionsService.recalculateCache();
        this.redirect(userData);
        // Instead of dispatching directly, return multiple actions
        return { userData, braintreeData, syncTransactions: true };
      }),
      map(({ braintreeData, syncTransactions }) => {
        // Create an array of actions to dispatch
        const actions = [];
        if (syncTransactions) {
          actions.push(syncTransactionsRequested({}));
          actions.push(stopLoadingTransactions());
        }
        let shouldSkip = false;
        if (braintreeData.paidThroughDate && braintreeData.status) {
          shouldSkip = this.shouldSkipBalanceUpdate(braintreeData.paidThroughDate, braintreeData.status);
        }
        // Add balance update action if needed
        if (!shouldSkip) {
          actions.push(AccountActions.balancesUpdateRequested());
        }
        // Return a custom action that will be handled by a separate effect
        return { type: POST_LOGIN_ACTIONS, actions } as PostLoginActionsPayload;
      })
    );
  });

  private shouldSkipBalanceUpdate(paidThroughDate: string, status: string): boolean {
    const paidDate = new Date(paidThroughDate);
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 30);
    return paidDate < sevenDaysAgo && status === 'Canceled';
  }

  // Handle post-login actions to avoid dispatching in effects
  postLoginActions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<PostLoginActionsPayload>(POST_LOGIN_ACTIONS),
      switchMap(action => {
        // Return the array of actions to be dispatched
        const actions = action.actions || [];
        // If no actions, return a no-op action to satisfy the Action type requirement
        return actions.length ? actions : of({ type: '[Login] No Actions' });
      })
    );
  });

  // Initialize login state from sessionStorage on application startup
  // This effect is triggered only on app initialization, not on regular login
  initializeLoginState$ = createEffect(() => {
    // Create a custom action type to initialize the app state
    const INIT_APP = '@ngrx/effects/init';

    return this.actions$.pipe(
      // Only trigger on app initialization, not on regular login actions
      ofType(INIT_APP),
      map(() => {
        try {
          // WIZ-2544: Improved error handling for login state initialization
          const token = window.sessionStorage.getItem('token');
          const wizeFiID = window.sessionStorage.getItem('wizeFiID');
          const userDataStr = window.sessionStorage.getItem('userData');
          const braintreeDataStr = window.sessionStorage.getItem('braintreeData');

          if (token && wizeFiID && userDataStr && braintreeDataStr) {
            try {
              const userData = JSON.parse(userDataStr);
              const braintreeData = JSON.parse(braintreeDataStr);

              // Validate session data consistency
              if (!this.sessionManager.validateSessionConsistency()) {
                this.logger.error('Session inconsistency detected during initialization');
                // Session manager will handle the inconsistency
                return { type: '[Login] Init No Action' };
              }

              // Use the proper action creator instead of a raw action object
              return LoginActions.initializeFromSession({
                id: wizeFiID,
                token,
                userData,
                braintreeData
              });
            } catch (e) {
              this.logger.error('Error restoring login state:', e);
              // Clean up corrupted session data
              this.sessionManager.clearSessionData(false);
            }
          }
        } catch (error) {
          this.logger.error('Unexpected error during login state initialization:', error);
        }
        return { type: '[Login] Init No Action' };
      }),
      filter(action => action.type !== '[Login] Init No Action'),
      catchError(error => {
        this.logger.error('Error in login state initialization:', error);
        return of({ type: '[Login] Init Error' });
      })
    );
  });

  // Effect to handle successful relogin
  reloginSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.reloginSuccessful),
      map(() => {
        try {
          // Check if we're in impersonation refresh mode
          const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
          const preventCoachSessionRestoration = window.sessionStorage.getItem('preventCoachSessionRestoration');
          const isImpersonationRefresh = loggedAsClientStr && preventCoachSessionRestoration === 'true';

          if (isImpersonationRefresh) {
            this.logger.info('Handling relogin during impersonation refresh');

            try {
              // Ensure client data is properly set
              const clientData = JSON.parse(loggedAsClientStr);
              this.logger.info(`Restoring coach impersonation state during relogin for client: – "${clientData.firstName} ${clientData.lastName}"`);

              // Make sure wizeFiID is set to client ID
              window.sessionStorage.setItem('wizeFiID', clientData.clientId);
              this.logger.info(`Set client wizeFiID in session storage: ${clientData.clientId}`);

              // Set a flag to indicate we're in an active impersonation session
              // This prevents unnecessary coach session storage attempts
              window.sessionStorage.setItem('activeImpersonationSession', 'true');

              // Ensure we have a valid token for API calls during impersonation
              const coachSessionStr = window.sessionStorage.getItem('coachSession');
              if (coachSessionStr) {
                try {
                  const coachSession = JSON.parse(coachSessionStr);
                  if (coachSession.token) {
                    // Use the coach token for API calls during impersonation
                    window.sessionStorage.setItem('token', coachSession.token);
                    this.logger.info('Using coach token for API calls during impersonation');
                  }
                } catch (tokenError) {
                  this.logger.error('Error extracting token from coach session during relogin:', tokenError);
                }
              }

              // Clear the prevention flag after successful relogin
              window.sessionStorage.removeItem('preventCoachSessionRestoration');
            } catch (clientError) {
              this.logger.error('Error processing client data during relogin:', clientError);
            }

            // Continue with normal flow
            this.cachedProjectionsService.recalculateCache();
            return { type: 'NO_ACTION' };
          }

          // Normal relogin flow (not during impersonation refresh)
          // WIZ-2544: Validate session consistency after relogin
          if (!this.sessionManager.validateSessionConsistency()) {
            this.logger.error('Session inconsistency detected after relogin');
            // Session manager will handle the inconsistency
            return logoutRequested();
          }
          this.cachedProjectionsService.recalculateCache();
          return { type: 'NO_ACTION' };
        } catch (error) {
          this.logger.error('Error during relogin process:', error);
          this.messageService.error('Error during relogin. Please try logging in again.', 5000);
          // Force logout on error
          return logoutRequested();
        }
      }),
      filter(action => action.type !== 'NO_ACTION')
    );
  });

  verificationCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.verificationCodeRequested),
      switchMap(props =>
        this.loginService.requestVerificationCode(props.email).pipe(
          map(() => LoginActions.verificationCodeSuccessful({ email: props.email })),
          catchError(err => of(LoginActions.verificationCodeFailed({ err })))
        )
      )
    );
  });

  resendVerificationCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.resendVerificationCodeRequested),
      switchMap(props =>
        this.loginService.requestVerificationCode(props.email).pipe(
          map(() => LoginActions.resendVerificationCodeSuccessful({ email: props.email })),
          catchError(err => of(LoginActions.resendVerificationCodeFailed({ err })))
        )
      )
    );
  });

  resendVerificationCodeSuccessfulEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.resendVerificationCodeSuccessful),
        tap(props => this.messageService.success('New code sent, please check your email: ' + props.email, 3000))
      );
    },
    { dispatch: false }
  );

  confirmRegistrationRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.confirmRegistrationRequested),
        switchMap(props =>
          this.loginService.confirmRegistration(props.email, props.verificationToken).pipe(
            tap(result => {
              this.messageService.success(result, 3000);
              this.router.navigate([LOGIN_PATH], { state: { email: props.email } });
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.error.message === 'Invalid verification code provided, please try again.') {
                this.messageService.error('Invalid verification code, please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  signupRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.signupRequested),
      map(props => {
        try {
          // WIZ-2544: Clear storage with error handling
          window.localStorage.clear();
          this.sessionManager.clearSessionData(false);
          window.localStorage.setItem('email', props.email);
        } catch (error) {
          this.logger.error('Error clearing storage during signup:', error);
        }
        return {
          nameFirst: props.nameFirst || '',
          nameLast: props.nameLast || '',
          email: props.email,
          password: props.password,
          parentAffiliateId: props.parentAffiliateId,
          isEnterpriseMember: props.isEnterpriseMember || false
        };
      }),
      switchMap(props =>
        this.loginService
          .signup(props.email, props.password, props.parentAffiliateId, props?.nameFirst, props?.nameLast, props?.isEnterpriseMember)
          .pipe(
            tap(
              () => this.router.navigateByUrl('/validate-email?u=' + encodeURIComponent(props.email)),
              (err: HttpErrorResponse) => (err.status === 400 ? this.messageService.error('A user with this email already exists.') : {})
            ),
            mapTo(LoginActions.signupSuccessful()),
            catchError(err => of(LoginActions.signupFailed()))
          )
      )
    );
  });

  changePasswordRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.changePasswordRequested),
        switchMap(props =>
          this.loginService.changePassword(props.oldPassword, props.newPassword).pipe(
            tap(() => this.messageService.success('Your password has changed successfully.', 5000)),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.messageService.error('Invalid password. Please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  updateEmailRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.updateEmailRequested),
      switchMap(props =>
        this.loginService.updateEmail(props.password, props.newEmail).pipe(
          tap(() => this.messageService.success('Your email has changed successfully.', 5000)),
          catchError(() => of<string>())
        )
      ),
      map(newEmail => LoginActions.updateEmailSuccessful({ newEmail }))
    );
  });

  resetPasswordCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.resetPasswordCodeRequested),
      switchMap(props =>
        this.loginService.requestPasswordResetCode(props.email).pipe(
          tap(() => this.messageService.success('A new verification code has been sent in your email.', 5000)),
          tap(() => this.router.navigateByUrl('/reset-password?u=' + encodeURIComponent(props.email))),
          map(() => LoginActions.verificationCodeSuccessful({ email: props.email })),
          catchError(err => {
            if (err.status === 404) {
              this.messageService.error('Please check your email to reset your password.');
            } else {
              this.messageService.error(err.error.message);
            }
            this.router.navigate([LOGIN_PATH]);
            return of(LoginActions.resetPasswordCodeFailedWithScreenChange());
          })
        )
      )
    );
  });

  resetPasswordRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.resetPasswordRequested),
        switchMap(props =>
          this.loginService.resetPassword(props.email, props.verificationCode, props.newPassword).pipe(
            tap(() => {
              this.messageService.success('Password reset successfully!', 3000);
              this.router.navigate([LOGIN_PATH]);
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.error?.message === 'Invalid verification code provided, please try again.') {
                this.messageService.error('Invalid verification code provided, please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  private redirect(userData: UserData): Promise<boolean> {
    if (!userData.setupCompletedDate) {
      this.store.dispatch(monthSelected({ yearMonth: userData.originalPlanYearMonth, autoCreate: true }));

      const lastStepCompleted = userData.setupStepCompleted;
      const lastStepCompletedUrl = this.getSetupStepUrls().find(i => i.step === lastStepCompleted)?.url;

      if (!lastStepCompletedUrl) {
        return this.router.navigateByUrl(this.getSetupStepUrls()[0].url);
      }

      return this.router.navigateByUrl(lastStepCompletedUrl);
    } else {
      return this.router.navigate([START_HERE_PATH]);
    }
  }

  private getSetupStepUrls() {
    return [
      { step: 1, url: SETUP_PATH + '/' + SETUP_GOALS_PATH },
      { step: 2, url: SETUP_PATH + '/' + SETUP_INCOME_PATH },
      { step: 3, url: SETUP_PATH + '/' + SETUP_ACCOUNTS_PATH },
      { step: 4, url: SETUP_PATH + '/' + SETUP_PROJECTIONS_PATH }
    ];
  }

  /**
   * Effect to handle initializeFromSession action
   * This ensures that when a session is restored (e.g., after browser refresh),
   * all necessary data is loaded correctly, including handling impersonation scenarios
   */
  initializeFromSessionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.initializeFromSession),
      switchMap(({ braintreeData }) => {
        this.logger.info('Initializing data after session restoration');

        // Check if we're in impersonation mode (coach viewing client data)
        const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
        let clientId = '';
        let isImpersonating = false;

        if (loggedAsClientStr) {
          try {
            // We're in impersonation mode, ensure we're using client data
            const client = JSON.parse(loggedAsClientStr);
            clientId = client.clientId;
            isImpersonating = true;
            this.logger.info(`Session restoration during impersonation mode for client: ${client.firstName} ${client.lastName}`);

            // Verify that wizeFiID matches the client ID
            const currentWizeFiID = window.sessionStorage.getItem('wizeFiID');
            if (currentWizeFiID !== clientId) {
              this.logger.warn(`Client ID mismatch during impersonation refresh. Expected: ${clientId}, Found: ${currentWizeFiID}`);
              // Fix the wizeFiID to ensure we load the client's data
              window.sessionStorage.setItem('wizeFiID', clientId);
              this.logger.info(`Reset wizeFiID to client ID: ${clientId}`);
            }

            // IMPORTANT: Set flags to handle impersonation state during page refresh
            window.sessionStorage.setItem('preventCoachSessionRestoration', 'true');
            window.sessionStorage.setItem('activeImpersonationSession', 'true');
            this.logger.info('Set flags to prevent coach session restoration during impersonation');

            // Get the coach token from session storage or coach session
            const coachSessionStr = window.sessionStorage.getItem('coachSession');

            // If we have a coach session, try to get a valid token from it
            if (coachSessionStr) {
              try {
                const coachSession = JSON.parse(coachSessionStr);
                if (coachSession.token) {
                  // Use the coach token for API calls during impersonation
                  window.sessionStorage.setItem('token', coachSession.token);
                  this.logger.info('Using coach token for impersonation API calls');
                }
              } catch (tokenError) {
                this.logger.error('Error extracting token from coach session:', tokenError);
              }
            }

            // Use the client ID for impersonation mode
            this.logger.info(`Triggering relogin for client: ${client.firstName} ${client.lastName}`);
          } catch (error) {
            this.logger.error('Error processing impersonation state during session restoration:', error);
            // Continue with normal restoration if there's an error
            isImpersonating = false;
          }
        } else {
          this.logger.info('Normal session restoration (not in impersonation mode)');
          // Clear the prevention flag if we're not in impersonation mode
          window.sessionStorage.removeItem('preventCoachSessionRestoration');
        }

        // For both impersonation and normal mode, use reloginRequested to handle all data loading
        // This is the same pattern used in exitImpersonationEffect$ in coach.effects.ts
        const token = window.sessionStorage.getItem('token') || '';
        const id = isImpersonating ? clientId : window.sessionStorage.getItem('wizeFiID') || '';

        // Ensure we're using the correct ID for the relogin request
        if (isImpersonating && id !== clientId) {
          this.logger.warn(`Correcting ID for relogin during impersonation. Using client ID: ${clientId}`);
        }

        return of(
          LoginActions.reloginRequested({
            token,
            id,
            url: window.location.pathname // Keep the user on the current page
          })
        );
      }),
      catchError(error => {
        this.logger.error('Error initializing data after session restoration:', error);
        return of({ type: '[Login] Init Data Error' });
      })
    );
  });

  public constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private messageService: MessageService,
    private store: Store,
    private cachedProjectionsService: CachedProjectionsService,
    private sessionManager: SessionManagerService,
    private logger: LoggerService
  ) {}
}
