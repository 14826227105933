import { createAction, props } from '@ngrx/store';
import { ChangePriorityResponse, StrategiesResponse, Strategy } from '@wizefi/entities';
import { FinishStrategyRequest, StrategiesFilter } from './strategies.type';

export class StrategiesActions {
  static fetchStrategies = createAction('[Strategies] fetch strategies');
  static fetchStrategiesSuccessful = createAction('[Strategies] fetch strategies successful', props<{ data: StrategiesResponse }>());
  static fetchStrategiesFailed = createAction('[Strategies] fetch strategies failed', props<{ err: any }>());
  static filterStrategies = createAction('[Strategies] filter strategies', props<StrategiesFilter>());
  static upsertPriorityRequested = createAction('[Strategies] upsert strategy priority', props<{ strategy: Strategy }>());
  static upsertPrioritySuccessful = createAction('[Strategies] upsert strategy priority successful', props<ChangePriorityResponse>());
  static upsertPriorityFailed = createAction('[Strategies] upsert strategy priority failed', props<{ err: any }>());
  static completeStepRequested = createAction('[Strategies] complete step', props<Strategy>());
  static completeStepSuccessful = createAction('[Strategies] complete step success', props<Strategy>());
  static completeStepFailed = createAction('[Strategies] complete step failure', props<{ err: any }>());
  static selectStrategy = createAction('[Strategies] select a strategy success', props<Strategy>());
  static finishStrategyRequested = createAction('[Strategies] on finish strategy', props<FinishStrategyRequest>());
  static openStrategyRequested = createAction('[Strategies] open strategy requested', props<{ strategy: Strategy }>());
  static openStrategySuccessful = createAction('[Strategies] open strategy successful', props<any>());
  static openStrategyFailed = createAction('[Strategies] open strategy failed', props<{ err: any }>());
}
