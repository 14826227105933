"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strategyWithoutIdSchema = exports.difficultySchema = exports.prioritySchema = exports.categorySchema = exports.subCategorySchema = exports.strategySchema = exports.strategyStatusSchema = void 0;
const zod_1 = require("zod");
exports.strategyStatusSchema = zod_1.z.enum([
    "priority-changed",
    "open",
    "inprogress",
    "done",
]);
exports.strategySchema = zod_1.z.object({
    // unique strategy id across all users, 
    // when user saves a strategy, this id is generated,
    // so if it is null, then strategy is not saved in db for that user
    id: zod_1.z.optional(zod_1.z.string()),
    // strategy id taken from dictionary
    dictionaryId: zod_1.z.string(),
    // strategy name taken from dictionary
    name: zod_1.z.string(),
    // savings for saved strategy taken from user (stored in db)
    actualSavings: zod_1.z.optional(zod_1.z.number()),
    // strategy estimated savings taken from dictionary
    estimatedSavings: zod_1.z.number(),
    // strategy weekly effort in minutes taken from dictionary
    weeklyEffortInMins: zod_1.z.number(),
    // strategy difficulty in minutes taken from dictionary
    difficulty: zod_1.z.number(),
    // strategy category taken from dictionary
    category: zod_1.z.number(),
    // strategy subCategory taken from dictionary
    subcategory: zod_1.z.number(),
    // strategy priority taken from dictionary,
    // but if stored for user, then it is taken from db for that user
    priority: zod_1.z.optional(zod_1.z.number()),
    // strategy progress taken from user (stored in db)
    progress: zod_1.z.optional(zod_1.z.number()),
    // strategy steps taken from user (stored in db)
    steps: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
        id: zod_1.z.number(),
        order: zod_1.z.number(),
        isDone: zod_1.z.boolean(),
        stepTitle: zod_1.z.string(),
        actionSteps: zod_1.z.array(zod_1.z.string()),
        time: zod_1.z.string(),
    }))),
    video: zod_1.z.string(),
    // status of a strategy, examples:
    // user change priory of a strategy, we should save it, but show user like it is not,
    // since user might want to set low priority to get rig of it (stay at the bottom)
    // other statuses are for real purpose, it means that user works with such strategies.
    status: zod_1.z.optional(exports.strategyStatusSchema),
    // user info who stored a strategy
    wizefiId: zod_1.z.optional(zod_1.z.string()),
    // info about video record
    videoNotes: zod_1.z.optional(zod_1.z.object({
        header: zod_1.z.optional(zod_1.z.object({
            text: zod_1.z.optional(zod_1.z.string()),
            bullets: zod_1.z.optional(zod_1.z.array(zod_1.z.string())),
        })),
        ideas: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
            data: zod_1.z.optional(zod_1.z.string()),
            type: zod_1.z.optional(zod_1.z.string()),
            text: zod_1.z.optional(zod_1.z.string()),
            sublevel: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
                type: zod_1.z.optional(zod_1.z.string()),
                data: zod_1.z.optional(zod_1.z.string()),
                text: zod_1.z.optional(zod_1.z.string()),
                thirdlevel: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
                    type: zod_1.z.optional(zod_1.z.string()),
                    data: zod_1.z.optional(zod_1.z.string()),
                    text: zod_1.z.optional(zod_1.z.string()),
                }))),
            }))),
        }))),
        tips: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
            data: zod_1.z.optional(zod_1.z.string()),
            type: zod_1.z.optional(zod_1.z.string()),
            text: zod_1.z.optional(zod_1.z.string()),
            sublevel: zod_1.z.optional(zod_1.z.array(zod_1.z.object({
                type: zod_1.z.optional(zod_1.z.string()),
                data: zod_1.z.optional(zod_1.z.string()),
                text: zod_1.z.optional(zod_1.z.string()),
            }))),
        }))),
    })),
    clicks: zod_1.z.optional(zod_1.z.number()),
});
exports.subCategorySchema = zod_1.z.object({
    id: zod_1.z.number(),
    name: zod_1.z.string(),
});
exports.categorySchema = zod_1.z.object({
    id: zod_1.z.number(),
    name: zod_1.z.string(),
    subcategories: zod_1.z.array(exports.subCategorySchema),
});
exports.prioritySchema = zod_1.z.object({
    id: zod_1.z.number(),
    name: zod_1.z.string(),
});
exports.difficultySchema = zod_1.z.object({
    id: zod_1.z.number(),
    name: zod_1.z.string(),
});
exports.strategyWithoutIdSchema = exports.strategySchema.omit({ id: true });
