/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { StrategiesService } from '@app/services/strategies.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StrategiesActions } from './strategies.actions';
import { of } from 'rxjs';

@Injectable()
export class StrategiesEffects {
  fetchStrategiesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StrategiesActions.fetchStrategies),
      mergeMap(() =>
        this.strategiesService.fetchStrategies().pipe(
          map(data => StrategiesActions.fetchStrategiesSuccessful({ data })),
          catchError(err => of(StrategiesActions.fetchStrategiesFailed({ err })))
        )
      )
    );
  });

  upsertStrategyPriorityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StrategiesActions.upsertPriorityRequested),
      mergeMap(props =>
        this.strategiesService.upsertStrategyPriority(props.strategy).pipe(
          map(data => StrategiesActions.upsertPrioritySuccessful(data)),
          catchError(err => of(StrategiesActions.upsertPriorityFailed({ err })))
        )
      )
    );
  });

  openStrategyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StrategiesActions.openStrategyRequested),
      mergeMap(props =>
        this.strategiesService.openStrategy(props.strategy).pipe(
          map(data => StrategiesActions.openStrategySuccessful(data)),
          catchError(err => of(StrategiesActions.openStrategyFailed({ err })))
        )
      )
    );
  });

  completeStepEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StrategiesActions.completeStepRequested),
      mergeMap(props =>
        this.strategiesService.completeStrategyStep(props).pipe(
          map(data => StrategiesActions.completeStepSuccessful(data)),
          catchError(err => of(StrategiesActions.completeStepFailed({ err })))
        )
      )
    );
  });

  finishStrategyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StrategiesActions.finishStrategyRequested),
      mergeMap(props =>
        this.strategiesService.completeStrategyStep(props.strategy).pipe(
          map(data => {
            props.callback();
            return StrategiesActions.completeStepSuccessful(data);
          }),
          catchError(err => of(StrategiesActions.completeStepFailed({ err })))
        )
      )
    );
  });

  public constructor(
    private actions$: Actions,
    private strategiesService: StrategiesService
  ) {}
}
