/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { InstitutionService } from '@app/services/institution.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { draftUnloaded } from '../draft/loaded-draft.actions';
import { selectLoadedDraft } from '../draft/loaded-draft.selectors';
import {
  institutionCreationFailed,
  institutionCreationRequest,
  institutionCreationSuccessful,
  institutionDeleteFailed,
  institutionDeleteRequest,
  institutionDeleteSuccessful,
  institutionGetFailed,
  institutionGetSuccessful,
  institutionsGetRequested,
  institutionUpdateFailed,
  institutionUpdateRequest,
  institutionUpdateSuccessful,
  updateInstitutionsOrder
} from '../institution/institution.actions';
import { monthSelected } from '../selected-month/selected-month.actions';
import { selectIsInOriginalPlanMonth, selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { of } from 'rxjs';

@Injectable()
export class InstitutionEffects {
  institutionsGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(institutionsGetRequested, monthSelected),
      concatLatestFrom(() => [this.store.select(selectSelectedMonth), this.store.select(selectLoadedDraft)]),
      mergeMap(([props, yearMonth, draftId]) =>
        this.institutionService.getInstitutions(yearMonth, props.autoCreate, draftId).pipe(
          map(institutions => institutionGetSuccessful({ institutions })),
          catchError(err => of(institutionGetFailed({ err })))
        )
      )
    );
  });

  institutionCreatedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(institutionCreationRequest),
      concatLatestFrom(() => [this.store.select(selectSelectedMonth), this.store.select(selectLoadedDraft)]),
      mergeMap(([action, yearMonth, loadedDraft]) =>
        this.institutionService.createInstitution(action.institution, yearMonth, action.draftTitle ?? loadedDraft).pipe(
          map(newInstitution => institutionCreationSuccessful({ newInstitution })),
          catchError(err => of(institutionCreationFailed({ err })))
        )
      )
    );
  });

  institutionUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(institutionUpdateRequest),
      concatLatestFrom(() => [this.store.select(selectSelectedMonth), this.store.select(selectLoadedDraft)]),
      mergeMap(([action, yearMonth, loadedDraft]) =>
        this.institutionService.editInstitution(action.institution, yearMonth, action.draftTitle ?? loadedDraft).pipe(
          map(updatedInstitution => institutionUpdateSuccessful({ updatedInstitution })),
          catchError(err => of(institutionUpdateFailed({ err })))
        )
      )
    );
  });

  institutionDeleteEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(institutionDeleteRequest),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      mergeMap(([action, loadedDraft, yearMonth]) =>
        this.institutionService.deleteInstitution(action.institution, yearMonth, action.draftTitle ?? loadedDraft).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([deletedInstitution, isOriginalPlan]) => institutionDeleteSuccessful({ deletedInstitution, isOriginalPlan })),
          catchError(err => of(institutionDeleteFailed({ err })))
        )
      )
    );
  });

  draftUnloadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftUnloaded),
      concatLatestFrom(() => [this.store.select(selectSelectedMonth)]),
      switchMap(([_, yearMonth]) => {
        // Proceed with the normal API call
        return this.institutionService.getInstitutions(yearMonth).pipe(
          map(institutions => institutionGetSuccessful({ institutions })),
          catchError(err => of(institutionGetFailed({ err })))
        );
      })
    );
  });

  updateInstitutionOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateInstitutionsOrder),
      withLatestFrom(this.store.select(selectSelectedMonth)),
      switchMap(([{ institutions }, yearMonth]) =>
        this.institutionService.updateInstitutionsBulk(institutions, yearMonth).pipe(
          map(() => ({
            type: '[Institution] Update Order Success',
            institutions
          })),
          catchError(error => of({ type: '[Institution] Update Order Failure', error }))
        )
      )
    );
  });

  public constructor(
    private store: Store,
    private actions$: Actions,
    private institutionService: InstitutionService
  ) {}
}
