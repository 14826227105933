import { Injectable } from '@angular/core';
import { BraintreeData } from '@wizefi/entities';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class BraintreeService {
  private readonly path = 'braintree';
  private readonly dataPath = '/data';
  private readonly clientToken = '/clientToken';
  private readonly createFirstSubscriptionPath = '/createFirstSubscription';
  private readonly createFirstSubscriptionEnterprisePath = '/createFirstSubscriptionEnterprise';
  private readonly paymentMethodPath = '/paymentMethod';
  private readonly subscriptionPath = '/subscription';

  private cachedBraintreeData$: Observable<BraintreeData> | undefined;

  constructor(
    private apiService: ApiService,
    private logger: LoggerService
  ) {}

  getBraintreeData(): Observable<BraintreeData> {
    // 1. First check if we have data in memory cache
    if (this.cachedBraintreeData$) {
      return this.cachedBraintreeData$;
    }

    // 2. Check if we're in impersonation mode
    const isImpersonating = window.sessionStorage.getItem('activeImpersonationSession') === 'true';
    const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');

    // 3. If we're in impersonation mode, try to use the coach's braintree data from the session
    if (isImpersonating && loggedAsClientStr) {
      const coachSessionStr = window.sessionStorage.getItem('coachSession');
      if (coachSessionStr) {
        try {
          const coachSession = JSON.parse(coachSessionStr);
          if (coachSession.braintreeData) {
            const braintreeData = JSON.parse(coachSession.braintreeData) as BraintreeData;
            this.logger.info('Using coach\'s braintree data from session during impersonation');
            this.cachedBraintreeData$ = of(braintreeData).pipe(shareReplay(1));
            return this.cachedBraintreeData$;
          }
        } catch (error) {
          this.logger.error('Error parsing coach\'s braintree data from session:', error);
          // Fall back to API call if there's an error
        }
      }
    }

    // 4. If we're not in impersonation mode or couldn't get the coach's braintree data,
    // make a fresh API call - no special options needed, let 401 errors trigger logout
    this.cachedBraintreeData$ = this.apiService.get(this.path + this.dataPath).pipe(shareReplay(1));
    return this.cachedBraintreeData$;
  }

  generateClientToken(): Observable<string> {
    return this.apiService.post(this.path + this.clientToken).pipe(map(result => result.message));
  }

  createFirstSubscription(firstName: string, lastName: string, paymentMethodNonce: string): Observable<BraintreeData> {
    return this.apiService
      .post(this.path + this.createFirstSubscriptionPath, { firstName, lastName, paymentMethodNonce })
      .pipe(tap(() => this.clearCache()));
  }

  createFirstSubscriptionEnterprise(firstName: string, lastName: string): Observable<BraintreeData> {
    return this.apiService.post(this.path + this.createFirstSubscriptionEnterprisePath, { firstName, lastName }).pipe(tap(() => this.clearCache()));
  }

  createSubscription(paymentMethodToken: string): Observable<any> {
    return this.apiService.post(this.path + this.subscriptionPath, { paymentMethodToken });
  }

  updatePaymentMethod(paymentMethodToken: string, paymentMethodNonce: string): Observable<{ token: string }> {
    return this.apiService.put(this.path + this.paymentMethodPath, { paymentMethodToken, paymentMethodNonce }).pipe(tap(() => this.clearCache()));
  }

  createPaymentMethod(paymentMethodNonce: string): Observable<{ token: string }> {
    return this.apiService.post(this.path + this.paymentMethodPath, { paymentMethodNonce }).pipe(tap(() => this.clearCache()));
  }

  updateSubscription(subscriptionId: string, paymentMethodToken: string): Observable<any> {
    return this.apiService.put(this.path + this.subscriptionPath, { subscriptionId, paymentMethodToken }).pipe(tap(() => this.clearCache()));
  }

  cancelSubscription(subscriptionId: string, cancelationReason: string): Observable<any> {
    return this.apiService.delete(this.path + this.subscriptionPath, { subscriptionId, cancelationReason }).pipe(tap(() => this.clearCache()));
  }

  clearCache() {
    this.cachedBraintreeData$ = undefined;
  }
}
