import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { ScreenLoginGuard } from '../utilities/guards/screen-login.guard';
import { AdminScreenGuard } from '../utilities/guards/admin-screen.guard';
import { BetaGuard } from './../utilities/guards/beta.guard';
import { BraintreeSubscriptionGuard } from '@app/utilities/guards/braintree-subscription.guard';
import {
  START_HERE_PATH,
  ACCOUNTS_PATH,
  ADMIN_PATH,
  COACH_PATH,
  DASHBOARD_PATH,
  EDIT_PLAN_PATH,
  ERROR_PATH,
  GO_PATH,
  LOGIN_PATH,
  PLAN_PATH,
  REGISTER_PATH,
  REPORTS_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_PATH,
  SETUP_PATH,
  SUBSCRIPTION_PATH,
  TRANSACTIONS_PATH,
  VALIDATE_EMAIL_PATH,
  STRATEGIES_PATH
} from './route-paths';

export const routes: Routes = [
  {
    path: ADMIN_PATH,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard, AdminScreenGuard],
    data: { title: 'Admin' }
  },
  {
    path: LOGIN_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: { title: 'Login' }
  },
  {
    path: GO_PATH,
    loadChildren: () => import('./go/go.module').then(m => m.GoModule),
    data: { title: 'Go' }
  },
  {
    path: REGISTER_PATH,
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    canActivate: [BetaGuard],
    data: {
      mode: SignupComponent.freeTrialSignupMode,
      title: 'Free Trial Sign Up'
    }
  },
  {
    path: SUBSCRIPTION_PATH,
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    canActivate: [ScreenLoginGuard],
    data: {
      mode: SignupComponent.subscriptionSignupMode,
      title: 'Subscription Sign Up'
    }
  },
  {
    path: SETUP_PATH,
    loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule),
    data: {
      title: 'Setup'
    }
  },
  {
    path: COACH_PATH,
    loadChildren: () => import('./coach/coach.module').then(m => m.CoachModule),
    data: {
      title: 'Coach'
    }
  },
  {
    path: PLAN_PATH,
    loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Plan'
    }
  },
  {
    path: EDIT_PLAN_PATH,
    loadChildren: () => import('./edit-plan/edit-plan.module').then(m => m.EditPlanModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Edit Plan'
    }
  },
  {
    path: ACCOUNTS_PATH,
    loadChildren: () => import('./accounts-page/accounts-page.module').then(m => m.AccountsPageModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Accounts'
    }
  },
  {
    path: VALIDATE_EMAIL_PATH,
    loadChildren: () => import('./validate-email/validate-email.module').then(m => m.ValidateEmailModule),
    data: {
      title: 'Validate Email'
    }
  },
  {
    path: RESET_PASSWORD_PATH,
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: DASHBOARD_PATH,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Dashboard'
    }
  },
  {
    path: STRATEGIES_PATH,
    loadChildren: () => import('./strategies/strategies.module').then(m => m.StrategiesModule),
    canActivate: [ScreenLoginGuard],
    data: {
      title: 'Strategies'
    }
  },
  {
    path: SETTINGS_PATH,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [ScreenLoginGuard],
    data: {
      title: 'Settings'
    }
  },
  {
    path: TRANSACTIONS_PATH,
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
    pathMatch: 'full',
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Transactions'
    }
  },
  {
    path: START_HERE_PATH,
    loadChildren: () => import('./start-here/start-here.module').then(m => m.StartHereModule),
    data: {
      title: 'Start Here'
    }
  },
  {
    path: REPORTS_PATH,
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [ScreenLoginGuard, BraintreeSubscriptionGuard],
    data: {
      title: 'Reports'
    }
  },
  {
    path: '',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full'
  },
  {
    path: ERROR_PATH,
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
    data: {
      title: 'Error'
    }
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    pathMatch: 'full',
    data: {
      title: 'Not Found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
