import { Injectable } from '@angular/core';
import { UserData } from '@wizefi/entities';
import { Store } from '@ngrx/store';
import { MessageService } from './message/message.service';
import { LoginActions } from '@app/state/login/login.actions';
import { LoggerService } from './logger.service';

/**
 * Service for managing and validating session data
 * Created for WIZ-2544 to address session management issues
 */
@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  constructor(
    private store: Store,
    private messageService: MessageService,
    private logger: LoggerService
  ) {}

  /**
   * Validates that the session data is consistent
   * Checks if the session data is valid and consistent
   * @returns boolean indicating if session is valid
   */
  public validateSessionConsistency(): boolean {
    const wizeFiID = window.sessionStorage.getItem('wizeFiID');
    const userDataStr = window.sessionStorage.getItem('userData');
    const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
    const preventCoachSessionRestoration = window.sessionStorage.getItem('preventCoachSessionRestoration');

    // Special handling for impersonation refresh scenario
    const isImpersonationRefresh = loggedAsClientStr && preventCoachSessionRestoration === 'true';

    if (isImpersonationRefresh) {
      this.logger.info('Session validation during impersonation refresh - using relaxed validation');
      // During impersonation refresh, we only need the client ID to be valid
      if (!wizeFiID) {
        this.logger.error('Session inconsistency during impersonation refresh: Missing wizeFiID');
        return false;
      }

      // If we have client data, ensure it matches the wizeFiID
      if (loggedAsClientStr) {
        try {
          const clientData = JSON.parse(loggedAsClientStr);
          if (clientData.clientId !== wizeFiID) {
            this.logger.error('Client ID mismatch during impersonation refresh');
            // Fix the wizeFiID to match the client ID
            window.sessionStorage.setItem('wizeFiID', clientData.clientId);
            this.logger.info(`Reset wizeFiID to client ID: ${clientData.clientId}`);
          }
        } catch (parseError) {
          this.logger.error('Error parsing client data during impersonation refresh:', parseError);
          return false;
        }
      }

      // For impersonation refresh, we'll be more lenient and allow the session
      return true;
    }

    // Normal validation for non-impersonation refresh scenarios
    if (!wizeFiID || !userDataStr) {
      this.logger.error('Session inconsistency detected: Missing wizeFiID or userData');
      return false;
    }

    try {
      const userData = JSON.parse(userDataStr) as UserData;

      // Check if we have valid user data with required fields
      if (!userData.email || !userData.nameFirst || !userData.nameLast) {
        this.logger.error('Session inconsistency detected: Invalid user data');
        this.handleSessionInconsistency();
        return false;
      }

      // If we're in impersonation mode, verify that wizeFiID matches the client ID
      if (loggedAsClientStr) {
        try {
          const clientData = JSON.parse(loggedAsClientStr);
          if (clientData.clientId !== wizeFiID) {
            this.logger.error('Session inconsistency detected: Client ID mismatch in impersonation mode');
            this.handleSessionInconsistency();
            return false;
          }
        } catch (parseError) {
          this.logger.error('Error parsing client data during validation:', parseError);
          this.handleSessionInconsistency();
          return false;
        }
      }

      // The UserData object doesn't contain the wizeFiID directly
      // Instead, we check for the existence of essential fields
      // and assume the session is valid if they exist

      return true;
    } catch (e) {
      this.logger.error('Error validating session data:', e);
      this.handleSessionInconsistency();
      return false;
    }
  }

  /**
   * Handles session inconsistency by forcing a relogin
   */
  private handleSessionInconsistency(): void {
    // Check if we're in impersonation mode
    const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
    const preventCoachSessionRestoration = window.sessionStorage.getItem('preventCoachSessionRestoration');
    const isImpersonationRefresh = loggedAsClientStr && preventCoachSessionRestoration === 'true';

    if (isImpersonationRefresh) {
      this.logger.info('Handling session inconsistency during impersonation refresh');
      try {
        // Try to recover the client ID from the impersonation data
        const clientData = JSON.parse(loggedAsClientStr);
        const clientId = clientData.clientId;

        if (clientId) {
          this.logger.info(`Attempting to recover impersonation session for client ID: ${clientId}`);

          // Clear session data but preserve impersonation state
          this.clearSessionData(true);

          // Ensure client ID is set correctly
          window.sessionStorage.setItem('wizeFiID', clientId);

          // Get the coach token from session storage or coach session
          let token = window.sessionStorage.getItem('token') || '';
          const coachSessionStr = window.sessionStorage.getItem('coachSession');

          // If we have a coach session, try to get a valid token from it
          if (coachSessionStr) {
            try {
              const coachSession = JSON.parse(coachSessionStr);
              if (coachSession.token) {
                // Use the coach token for API calls during impersonation
                token = coachSession.token;
                window.sessionStorage.setItem('token', token);
                this.logger.info('Using coach token for impersonation API calls during recovery');
              }
            } catch (tokenError) {
              this.logger.error('Error extracting token from coach session during recovery:', tokenError);
            }
          }

          // Force relogin with client ID and appropriate token
          this.store.dispatch(
            LoginActions.reloginRequested({
              token,
              id: clientId,
              url: ''
            })
          );
          return;
        }
      } catch (error) {
        this.logger.error('Error recovering from impersonation session inconsistency:', error);
      }
    }

    // Standard handling for non-impersonation or failed impersonation recovery
    // Clear session storage to prevent further issues
    this.clearSessionData();

    // Notify user
    this.messageService.info('Your session has been reset due to data inconsistency. Please log in again.', 5000);

    // Force relogin
    this.store.dispatch(
      LoginActions.reloginRequested({
        token: window.sessionStorage.getItem('token') || '',
        id: window.sessionStorage.getItem('wizeFiID') || '',
        url: ''
      })
    );
  }

  /**
   * Clears all session data
   * @param preserveCoachImpersonation If true, preserves coach impersonation state
   */
  public clearSessionData(preserveCoachImpersonation = false): void {
    // Save coach impersonation state if needed
    const loggedAsClientStr = preserveCoachImpersonation ? window.sessionStorage.getItem('loggedAsClient') : null;
    const coachSessionStr = preserveCoachImpersonation ? window.sessionStorage.getItem('coachSession') : null;

    // Clear all session data
    window.sessionStorage.clear();

    // Restore coach impersonation state if needed
    if (loggedAsClientStr) {
      window.sessionStorage.setItem('loggedAsClient', loggedAsClientStr);
    }

    // Restore coach session data if needed
    if (coachSessionStr) {
      window.sessionStorage.setItem('coachSession', coachSessionStr);
    }
  }

  /**
   * Stores the coach's session data before impersonating a client
   * This allows us to restore the coach's session when they log out as a client
   * @returns boolean indicating if storage was successful
   */
  public storeCoachSession(): boolean {
    try {
      // Check if we're already in an active impersonation session
      // If so, we don't need to store the coach session again
      const activeImpersonationSession = window.sessionStorage.getItem('activeImpersonationSession');
      if (activeImpersonationSession === 'true') {
        this.logger.info('Coach session storage skipped - already in active impersonation session');
        return true; // Return true to avoid triggering errors in the calling code
      }

      // Check if we're in impersonation refresh mode
      const preventCoachSessionRestoration = window.sessionStorage.getItem('preventCoachSessionRestoration');
      if (preventCoachSessionRestoration === 'true') {
        this.logger.info('Coach session storage skipped during impersonation refresh');
        return true; // Return true to avoid triggering errors in the calling code
      }

      const token = window.sessionStorage.getItem('token');
      const wizeFiID = window.sessionStorage.getItem('wizeFiID');
      const userData = window.sessionStorage.getItem('userData');
      const braintreeData = window.sessionStorage.getItem('braintreeData');

      // Validate that we have the minimum required data to store
      if (!token || !wizeFiID || !userData) {
        this.logger.error('Missing required coach session data');
        return false;
      }

      // Create a coach session object with all the necessary data
      const coachSession = {
        token,
        wizeFiID,
        userData,
        braintreeData: braintreeData || null,
        timestamp: new Date().toISOString() // Add timestamp for debugging purposes
      };

      // Store the coach session data
      window.sessionStorage.setItem('coachSession', JSON.stringify(coachSession));
      this.logger.info('Coach session successfully stored for impersonation');
      return true;
    } catch (error) {
      this.logger.error('Error storing coach session:', error);
      return false;
    }
  }

  /**
   * Restores the coach's session data after logging out as a client
   * @returns boolean indicating if restoration was successful
   */
  public restoreCoachSession(): boolean {
    try {
      // Check if coach session restoration is prevented (during impersonation refresh)
      const preventRestoration = window.sessionStorage.getItem('preventCoachSessionRestoration');
      if (preventRestoration === 'true') {
        this.logger.info('Coach session restoration prevented during impersonation mode');
        return false;
      }

      const coachSessionStr = window.sessionStorage.getItem('coachSession');
      if (!coachSessionStr) {
        this.logger.error('No coach session found to restore');
        return false;
      }

      const coachSession = JSON.parse(coachSessionStr);

      // Validate coach session data before restoration
      if (!coachSession.token || !coachSession.wizeFiID || !coachSession.userData) {
        this.logger.error('Invalid coach session data found');
        return false;
      }

      // Clear current session data before restoring coach data
      // This ensures we don't have any leftover client data
      const preserveCoachSession = true;
      this.clearSessionData(preserveCoachSession);

      // Restore the coach's session data with additional logging
      this.logger.info(`Restoring coach wizeFiID: ${coachSession.wizeFiID}`);

      // First clear all existing session items to avoid any caching issues
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('wizeFiID');
      window.sessionStorage.removeItem('userData');
      window.sessionStorage.removeItem('braintreeData');

      // Now set all the coach session data
      window.sessionStorage.setItem('token', coachSession.token);
      window.sessionStorage.setItem('wizeFiID', coachSession.wizeFiID);
      window.sessionStorage.setItem('userData', coachSession.userData);
      if (coachSession.braintreeData) {
        window.sessionStorage.setItem('braintreeData', coachSession.braintreeData);
      }

      // Verify the restoration was successful with detailed logging
      const restoredWizeFiID = window.sessionStorage.getItem('wizeFiID');
      this.logger.info(`Verification - Expected wizeFiID: ${coachSession.wizeFiID}, Actual: ${restoredWizeFiID}`);

      if (restoredWizeFiID !== coachSession.wizeFiID) {
        this.logger.error('Coach session restoration verification failed - wizeFiID mismatch');

        // Try one more time as a last resort
        window.sessionStorage.setItem('wizeFiID', coachSession.wizeFiID);

        // Check if it worked
        const finalWizeFiID = window.sessionStorage.getItem('wizeFiID');
        if (finalWizeFiID !== coachSession.wizeFiID) {
          this.logger.error('Critical session inconsistency detected - forcing logout for security');
          // Clear all session data for security
          this.clearSessionData(false);
          // Redirect to login page will happen at the application level
          return false;
        }

        this.logger.warn('Coach session restoration required retry but succeeded');
      }

      // Remove the coach session and client impersonation data
      window.sessionStorage.removeItem('coachSession');
      window.sessionStorage.removeItem('loggedAsClient');

      this.logger.info('Coach session successfully restored');
      return true;
    } catch (error) {
      this.logger.error('Error restoring coach session:', error);
      return false;
    }
  }
}
