import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import {
  START_HERE_PATH,
  ACCOUNTS_PATH,
  ADMIN_PATH,
  COACH_PATH,
  DASHBOARD_PATH,
  STRATEGIES_PATH,
  EDIT_PLAN_PATH,
  ERROR_PATH,
  LOGIN_PATH,
  PLAN_PATH,
  REGISTER_PATH,
  REPORTS_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_PATH,
  SETUP_PATH,
  SETUP_SETTINGS_PATH,
  SUBSCRIPTION_PATH,
  TRANSACTIONS_PATH,
  VALIDATE_EMAIL_PATH
} from '@app/modules/route-paths';
import { Store } from '@ngrx/store';
import { UserDataSelectors } from '@app/state/user-data/user-data.selectors';
import { Observable } from 'rxjs';
import { selectIsLoggedIn } from '@app/state/login/login.selectors';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { IntercomService } from '@app/services/intercom.service';
import { CoachSelectors } from '@app/state/coach/coach.selectors';
import { CoachActions } from '@app/state/coach/coach.actions';
import { CoachClientData } from '@wizefi/entities';
import { selectInstitutions } from '@app/state/institution/institution.root.selectors';
import { MessageService } from '@app/services/message/message.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  readonly coachPath = COACH_PATH;
  readonly settingsPath = SETTINGS_PATH;

  badgeContent = 0;

  name$!: Observable<string>;
  isLoggedIn$!: Observable<boolean>;
  isInSetup$!: Observable<boolean>;
  loggedAsClient$!: Observable<CoachClientData | undefined>;
  menuOptions!: MenuItem[];
  @HostBinding('class.remove-sidebar') pageWithoutSidebar = true;
  @HostBinding('class.hide-sidebar') hideSidebar = false;

  constructor(
    public router: Router,
    private store: Store,
    private intercomService: IntercomService,
    private messageService: MessageService
  ) {}

  switchRoute(route: string[]) {
    return this.router.navigate(route);
  }

  ngOnInit() {
    this.isLoggedIn$ = this.store.select(selectIsLoggedIn);
    this.name$ = this.store.select(UserDataSelectors.selectFullName);
    this.loggedAsClient$ = this.store.select(CoachSelectors.loggedAsClient);

    const navigationFinish$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

    navigationFinish$.subscribe(() => {
      const pathSegments = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map(i => i.path);
      const pathsToRemoveSidebar = [
        LOGIN_PATH,
        REGISTER_PATH,
        VALIDATE_EMAIL_PATH,
        RESET_PASSWORD_PATH,
        SUBSCRIPTION_PATH,
        EDIT_PLAN_PATH,
        ADMIN_PATH,
        ERROR_PATH
      ];
      this.pageWithoutSidebar = pathSegments.some(segment => pathsToRemoveSidebar.includes(segment));
    });

    this.isInSetup$ = navigationFinish$.pipe(map((e: NavigationEnd) => e.url.includes(SETUP_PATH)));

    this.menuOptions = [
      {
        route: START_HERE_PATH,
        click: START_HERE_PATH,
        icon: 'icon_start',
        title: 'Start Here'
      },
      {
        route: STRATEGIES_PATH,
        click: STRATEGIES_PATH,
        icon: 'icon_strategy',
        title: 'Strategies'
      },
      {
        route: DASHBOARD_PATH,
        click: DASHBOARD_PATH,
        icon: 'icon_dashboard',
        title: 'Dashboard'
      },
      {
        route: PLAN_PATH,
        click: PLAN_PATH,
        icon: 'icon_plan',
        title: 'Plan'
      },
      {
        route: ACCOUNTS_PATH,
        click: ACCOUNTS_PATH,
        icon: 'icon_accounts',
        title: 'Accounts'
      },
      {
        route: TRANSACTIONS_PATH,
        click: TRANSACTIONS_PATH,
        icon: 'icon_transactions',
        title: 'Transactions'
      },
      {
        route: REPORTS_PATH,
        click: REPORTS_PATH,
        icon: 'insights',
        isMaterialIcon: true,
        title: 'Reports'
      },
      {
        route: COACH_PATH,
        click: COACH_PATH,
        icon: 'group',
        isMaterialIcon: true,
        title: 'Clients'
      },
      {
        route: 'intercom',
        click: 'showIntercom',
        icon: 'icon_help',
        title: 'Help'
      }
    ];

    this.store
      .select(selectInstitutions)
      .pipe(
        map(institutions => institutions.some(inst => inst.error === 'ITEM_NOT_FOUND')),
        distinctUntilChanged()
      )
      .subscribe(hasErrors => {
        if (hasErrors) {
          const message = `Some of your accounts have disconnected.<br/>
        <a class="message-link-accounts-disconnected">
        Click here
        </a>
         to resync.`;
          this.messageService.info(message);
        }
      });

    this.store
      .select(selectInstitutions)
      .pipe(map(institutions => institutions.filter(inst => inst.error === 'ITEM_LOGIN_REQUIRED' || inst.error === 'ITEM_NOT_FOUND').length))
      .subscribe(count => {
        this.badgeContent = count;
      });
  }

  optionClick(option: MenuItem) {
    if (option.click === 'showIntercom') {
      this.intercomService.show();
      return;
    }
    this.switchRoute([option.click]);
  }

  navigateToSettings(isInSetup: boolean) {
    this.router.navigate(isInSetup ? [SETUP_PATH, SETUP_SETTINGS_PATH] : [SETTINGS_PATH], { fragment: 'profile' });
  }

  unlogFromClient() {
    this.store.dispatch(CoachActions.logoutAsClient());
  }
}

interface MenuItem {
  route: string;
  click: string;
  icon: string;
  title: string;
  isMaterialIcon?: boolean;
}
