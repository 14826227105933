import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// Define log levels
export enum LogLevel {
  none = 0,
  error = 1,
  warn = 2,
  info = 3,
  debug = 4
}

/**
 * Logger service for centralized logging in the application
 * Helps avoid direct console usage which fails linting rules
 */
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  // Get current log level from environment or default to Error in production, Debug otherwise
  private logLevel: LogLevel = environment.logLevel !== undefined ? environment.logLevel : environment.production ? LogLevel.error : LogLevel.debug;
  /**
   * Log an informational message
   * @param message The message to log
   * @param optionalParams Additional parameters to log
   */
  info(message: string, ...optionalParams: any[]): void {
    if (this.logLevel >= LogLevel.info) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }

  /**
   * Log an error message
   * @param message The error message to log
   * @param optionalParams Additional parameters to log
   */
  error(message: string, ...optionalParams: any[]): void {
    if (this.logLevel >= LogLevel.error) {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams);
    }
  }

  /**
   * Log a warning message
   * @param message The warning message to log
   * @param optionalParams Additional parameters to log
   */
  warn(message: string, ...optionalParams: any[]): void {
    if (this.logLevel >= LogLevel.warn) {
      // eslint-disable-next-line no-console
      console.warn(message, ...optionalParams);
    }
  }

  /**
   * Log a debug message
   * @param message The debug message to log
   * @param optionalParams Additional parameters to log
   */
  debug(message: string, ...optionalParams: any[]): void {
    if (this.logLevel >= LogLevel.debug) {
      // eslint-disable-next-line no-console
      console.debug(message, ...optionalParams);
    }
  }

  /**
   * Set the log level at runtime
   * @param level The log level to set
   */
  setLogLevel(level: LogLevel): void {
    this.logLevel = level;
  }
}
