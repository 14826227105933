/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
export const plaidErrorMessages: { [key: string]: string } = {
  ITEM_LOGIN_REQUIRED: `Warning: One or more of your accounts has the following issue:
                          “Username or password incorrect: If you've recently updated your
                          account with this institution, be sure you're entering your updated credentials”.`,
  ITEM_NO_ERROR: 'There is no error with your account at this time.',
  INTERNAL_SERVER_ERROR: 'We are experiencing some technical difficulties. Please try again later.',
  RATE_LIMIT_EXCEEDED: 'Too many requests were made in a short period of time. Please wait a moment and try again.',
  ACCESS_NOT_GRANTED: `Insufficient Sharing Permissions: There was an error connecting to your account.
                        Try linking your account again by selecting the required information to share with this application.`,
  INSTANT_MATCH_FAILED: `Item cannot be verified through Instant Match. Ensure you are correctly enabling all auth features in Link.`,
  INSUFFICIENT_CREDENTIALS: `Insufficient authorization was provided to complete the request`,
  INVALID_CREDENTIALS: `The credentials you provided were incorrect: Check that your credentials are the same that you use for this institution`,
  INVALID_MFA: `The credentials you provided were incorrect: For security reasons, your account may be locked after several unsuccessful attempts`,
  INVALID_SEND_METHOD: `The provided MFA send_method was invalid`,
  ITEM_LOCKED: `Too many attempts: Your account is locked for security reasons. Reset your bank username and password, and then try again.`,
  ITEM_NOT_SUPPORTED: `Account not currently supported: Your account is not currently supported. Please log in using a different account`,
  NO_ACCOUNTS: `No compatible accounts: Your credentials are correct, but we couldn’t find any accounts with this institution that are compatible with this application. Try another account, financial institution, or check for another connection method.`,
  INVALID_PHONE_NUMBER: `We couldn't verify that the number provided is a valid number. Please re-enter your phone number to try again.`,
  INVALID_OTP: `Check that the code you entered is the same code that was sent to you`,
  INVALID_UPDATED_USERNAME: `Try entering your bank account username again. If you recently changed it, you may need to un-link your account and then re-link.`,
  MFA_NOT_SUPPORTED: `Your account could not be connected because the multi-factor authentication method it uses is not currently supported.
                      Please try a different account.`,
  NO_INVESTMENT_ACCOUNTS: `None of your accounts are investment accounts. Please connect using a different bank`,
  NO_LIABILITY_ACCOUNTS: `No liability accounts: None of your accounts are liability accounts. Please connect using a different bank`,
  PASSWORD_RESET_REQUIRED: `Your account requires a password reset. Please log in to your bank’s website to reset your password.`,
  PRODUCT_NOT_ENABLED: `The product you are trying to access is not enabled on your account.
                        Please contact your financial institution for more information.`,
  PRODUCT_NOT_READY: `The product you are trying to access is not ready for use.`,
  PRODUCTS_NOT_SUPPORTED: 'The products you are trying to access are not supported by your financial institution.',
  USER_SETUP_REQUIRED: 'Log in to your bank and update your account. Then, return to continue.',
  USER_INPUT_TIMEOUT: `Session expired: Please re-enter your information to link your accounts.`,
  INSTITUTION_DOWN: `Please try connecting a different account: There was a problem processing your request.
                    Your account could not be connected at this time`,
  INSTITUTION_NOT_RESPONDING: `Could not connect to your institution: There was a problem processing your request.
                              We recommend trying another institution.`,
  INSTITUTION_NOT_AVAILABLE: `Institution not available: There was a problem processing your request.`,
  INSTITUTION_NO_LONGER_SUPPORTED: `Please try connecting a different account: There was a problem processing your request.
                                    Your account could not be connected at this time.`,
  UNAUTHORIZED_INSTITUTION: `Unauthorized institution: There was a problem processing your request.`,
  INSTITUTION_REGISTRATION_REQUIRED: `Insufficient authorization was provided to complete the request`
};
