"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transactionRuleWithoutIdSchema = exports.transactionRuleSchema = void 0;
const zod_1 = require("zod");
const account_category_1 = require("./account-category");
const account_type_1 = require("./account-type");
exports.transactionRuleSchema = zod_1.z.object({
    id: zod_1.z.string(),
    wizefiId: zod_1.z.string(),
    sourceTransaction: zod_1.z.optional(zod_1.z.string()),
    type: zod_1.z.optional(account_type_1.accountTypeSchema),
    category: zod_1.z.optional(account_category_1.accountCategorySchema),
    budgetShadowCategory: zod_1.z.optional(account_category_1.accountCategorySchema),
    account: zod_1.z.optional(zod_1.z.string()),
    transactionName: zod_1.z.optional(zod_1.z.string()),
    sourceAccount: zod_1.z.optional(zod_1.z.string()),
    sourceInstitution: zod_1.z.optional(zod_1.z.string()),
    amount: zod_1.z.optional(zod_1.z.number()),
    excludeTransactions: zod_1.z.optional(zod_1.z.boolean())
});
exports.transactionRuleWithoutIdSchema = exports.transactionRuleSchema.omit({
    id: true,
    wizefiId: true
});
