import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StrategiesResponse, StrategyWithoutId, Strategy, ChangePriorityResponse } from '@wizefi/entities';

@Injectable({
  providedIn: 'root'
})
export class StrategiesService {
  private readonly path = 'strategies';

  constructor(private api: ApiService) {}

  fetchStrategies(): Observable<StrategiesResponse> {
    return this.api.get(this.path);
  }

  createStrategy(strategy: StrategyWithoutId): Observable<StrategiesResponse> {
    return this.api.post(this.path, strategy);
  }

  fetchStrategyById(id: string): Observable<StrategiesResponse> {
    return this.api.get(this.path + '/' + id);
  }

  upsertStrategyPriority(strategy: Strategy): Observable<ChangePriorityResponse> {
    return this.api.post(this.path + '/priority-upsert', strategy);
  }

  completeStrategyStep(strategy: Strategy): Observable<Strategy> {
    return this.api.post(this.path + '/complete-step', strategy);
  }

  openStrategy(strategy: Strategy): Observable<Strategy> {
    return this.api.post(this.path + '/open-strategy', strategy);
  }
}
