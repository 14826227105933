import { createAction, props } from '@ngrx/store';
import { CoachClientData } from '@wizefi/entities';

/**
 * Actions related to coach functionality, particularly impersonation flow.
 *
 * WIZ-2544: Simplified the impersonation flow to make it more reliable and maintainable.
 */
export class CoachActions {
  // Action to start client impersonation
  static logAsClient = createAction('[Coach] Start Client Impersonation', props<{ client: CoachClientData }>());

  // Action to exit client impersonation
  static logoutAsClient = createAction('[Coach] Exit Client Impersonation');

  // Legacy action kept for backward compatibility
  static sessionRestorationComplete = createAction('[Coach] Session Restoration Complete');

  // New action for the simplified flow
  static exitImpersonationComplete = createAction('[Coach] Exit Impersonation Complete');

  // Error actions for better error handling
  static exitImpersonationError = createAction('[Coach] Exit Impersonation Error', props<{ error: any }>());
}
