import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LoginActions } from '@app/state/login/login.actions';
import { selectLoginLoadingStatus } from '@app/state/login/login.selectors';
import { logoutRequested } from '@app/state/screen.actions';
import { SessionManagerService } from '@app/services/session-manager.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScreenLoginGuard implements CanActivate {
  constructor(
    private store: Store,
    private sessionManager: SessionManagerService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(selectLoginLoadingStatus).pipe(
      map(status => {
        if (status !== 'success') {
          const { id, token } = this.getCredentialsFromLocalStorage();
          if (!token || !id) {
            this.store.dispatch(logoutRequested());
            return false;
          }

          // WIZ-2544: Validate session consistency before proceeding
          if (!this.sessionManager.validateSessionConsistency()) {
            // Session manager will handle the inconsistency and trigger relogin if needed
            return true;
          }

          this.store.dispatch(LoginActions.reloginRequested({ token, id, url: route.url.toString() }));
          return true;
        }
        return true;
      })
    );
  }

  private getCredentialsFromLocalStorage(): { token: string | null; id: string | null } {
    if (!window.sessionStorage.getItem('token') || !window.sessionStorage.getItem('wizeFiID')) {
      return { id: null, token: null };
    }
    const token = window.sessionStorage.getItem('token');
    const id = window.sessionStorage.getItem('wizeFiID');
    return { token, id };
  }
}
