/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { COACH_PATH, PLAN_PATH } from '@app/modules/route-paths';
import { ApiService } from '@app/services/api.service';
import { DraftService } from '@app/services/draft.service';
import { LoggerService } from '@app/services/logger.service';
import { MessageService } from '@app/services/message/message.service';
import { SessionManagerService } from '@app/services/session-manager.service';
import { TransactionRuleService } from '@app/services/transaction-rule.service';
import { TransactionService } from '@app/services/transaction.service';
import { UserDataService } from '@app/services/user-data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { catchError, map, switchMap, tap, filter } from 'rxjs/operators';
import { AccountActions } from '../account/account.actions';
import { institutionsGetRequested } from '../institution/institution.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { transactionRulesGetRequested } from '../transaction-rules/transaction-rules.actions';
import { transactionsGetRequested } from '../transaction/transaction.actions';
import { UserDataActions } from '../user-data/user-data.actions';
import { CoachActions } from './coach.actions';
import { CoachClientData, UserData } from '@wizefi/entities';
import { LoginActions } from '../login/login.actions';

@Injectable()
export class CoachEffects {
  loggedInAsClientEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoachActions.logAsClient),
      tap(action => {
        try {
          // WIZ-2544: Save impersonation state to sessionStorage with error handling
          window.sessionStorage.setItem('loggedAsClient', JSON.stringify(action.client));

          // Store the coach's session data for later restoration
          const sessionStored = this.sessionManager.storeCoachSession();
          if (!sessionStored) {
            this.logger.warn('Failed to store coach session data, impersonation may not restore properly');
            // We continue with impersonation but log a warning
          } else {
            this.logger.info('Coach impersonation state saved successfully');
          }
        } catch (error) {
          this.logger.error('Error saving coach impersonation state:', error);
          // Continue with the process even if saving to sessionStorage fails
        }
      }),
      // WIZ-2544: Load client data before proceeding with navigation
      switchMap(action => {
        // Get the client ID from the action
        const clientId = action.client.clientId;

        // Note: We're keeping the coach's token and braintree data in session storage
        // No need to explicitly retrieve or set them as we're not clearing session storage here

        // Clear session data first to ensure a clean state
        window.sessionStorage.removeItem('userData');
        window.sessionStorage.removeItem('wizeFiID');

        // Set the client ID in session storage so API requests will use it
        window.sessionStorage.setItem('wizeFiID', clientId);
        this.logger.info(`Set client wizeFiID in session storage: ${clientId}`);

        // Now fetch the client's data using the UserDataService
        // The API request will use the wizeFiID we just set
        return this.userDataService.getUserData().pipe(
          map(userData => {
            if (!userData) {
              throw new Error('Invalid client data received');
            }
            return userData;
          }),
          tap(userData => {
            try {
              // Store the client's user data in session storage
              this.logger.info('Setting client userData in session storage');
              window.sessionStorage.setItem('userData', JSON.stringify(userData));
              // Coach's braintree data remains in session storage (not modified)

              // Verify that wizeFiID is still set correctly (in case something changed it)
              const storedWizeFiID = window.sessionStorage.getItem('wizeFiID');
              if (storedWizeFiID !== clientId) {
                this.logger.warn(`wizeFiID changed during API call, resetting to: ${clientId}`);
                window.sessionStorage.setItem('wizeFiID', clientId);
              }

              this.logger.info('Client session data stored successfully');
            } catch (error) {
              this.logger.error('Error storing client session data:', error);
              throw error; // Rethrow to be caught by the error handler
            }
          }),
          switchMap(() => {
            // Navigate to the plan path after client data is loaded
            return this.router.navigate([PLAN_PATH]).catch(error => {
              this.logger.error('Navigation error during client impersonation:', error);
              return [false]; // Return navigation result to continue the stream
            });
          })
        );
      }),
      tap(() => this.clearCaches()),
      this.getData(),
      catchError(error => {
        this.logger.error('Error during client impersonation process:', error);
        // If there was an error, attempt to restore the coach session
        try {
          this.sessionManager.restoreCoachSession();
        } catch (restoreError) {
          this.logger.error('Failed to restore coach session after impersonation error:', restoreError);
        }
        // Notify user of the error
        this.messageService.error('Failed to impersonate client. Please try again.');
        // Return empty observable to complete this effect
        return of({ type: '[Coach] Impersonation Error' });
      })
    );
  });

  /**
   * Simplified effect to handle the entire coach exit impersonation flow in one cohesive process.
   *
   * This replaces the previous fragmented approach that used multiple effects and session storage flags.
   * The new implementation provides a more straightforward, maintainable flow with better error handling.
   *
   * WIZ-2544: This addresses the issue of client data showing instead of coach data after exiting impersonation.
   */
  exitImpersonationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoachActions.logoutAsClient),
      tap(() => {
        this.logger.info('Starting coach exit impersonation process');
        // Clear any client-specific caches
        this.clearCaches();
      }),
      switchMap(() => {
        try {
          // Step 1: Restore coach session from storage
          this.logger.info('Restoring coach session from storage');
          const restored = this.sessionManager.restoreCoachSession();

          if (!restored) {
            this.logger.error('Failed to restore coach session');
            // Ensure we're not in impersonation mode even if restoration failed
            window.sessionStorage.removeItem('loggedAsClient');
            return of({ type: '[Coach] Session Restoration Failed' });
          }

          // Step 2: Get the restored session data
          const token = window.sessionStorage.getItem('token') || '';
          const id = window.sessionStorage.getItem('wizeFiID') || '';

          // Step 3: Force a relogin to refresh all data
          this.logger.info('Coach session restored, forcing relogin to refresh data');
          return of(
            LoginActions.reloginRequested({
              token,
              id,
              url: COACH_PATH
            })
          );
        } catch (error) {
          this.logger.error('Error during coach session restoration:', error);
          return of(CoachActions.exitImpersonationError({ error }));
        }
      }),
      catchError(error => {
        this.logger.error('Unexpected error during exit impersonation:', error);
        // Force navigation to coach path even if there was an error
        this.router.navigate([COACH_PATH]).catch(navError => {
          this.logger.error('Final navigation error:', navError);
        });
        return of(CoachActions.exitImpersonationError({ error }));
      })
    );
  });

  // Initialize coach state from sessionStorage on application startup
  initializeCoachState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.loginSuccessful, LoginActions.initializeFromSession),
      map(() => {
        try {
          // First check if we're returning from a client impersonation session
          // This would be indicated by the presence of coachSession data
          const coachSessionStr = window.sessionStorage.getItem('coachSession');
          if (coachSessionStr) {
            this.logger.info('Found coach session data, attempting to restore');
            const restored = this.sessionManager.restoreCoachSession();
            if (restored) {
              this.logger.info('Successfully restored coach session data');
              // We'll return a no-op action here since the session data is already restored
              // and the login state will be updated by the initializeFromSession action
              return { type: '[Coach] Session Restored' };
            } else {
              this.logger.warn('Failed to restore coach session data');
            }
          }

          // Check if we need to restore an active client impersonation
          const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
          if (loggedAsClientStr) {
            try {
              const client = JSON.parse(loggedAsClientStr) as CoachClientData;

              // Validate the client data before restoring
              if (!client || !client.clientId || !client.firstName || !client.lastName) {
                throw new Error('Invalid client data structure');
              }

              this.logger.info('Restoring coach impersonation state for client:', `${client.firstName} ${client.lastName}`);
              return CoachActions.logAsClient({ client });
            } catch (e) {
              // If there's an error parsing the stored data, remove it
              window.sessionStorage.removeItem('loggedAsClient');
              this.logger.error('Error restoring coach impersonation state:', e);
            }
          }
        } catch (error) {
          this.logger.error('Unexpected error during coach state initialization:', error);
        }
        return { type: '[Coach] Init No Action' };
      }),
      filter(action => action.type !== '[Coach] Init No Action'),
      catchError(error => {
        this.logger.error('Error in coach state initialization:', error);
        // Clean up any potentially corrupted state
        try {
          window.sessionStorage.removeItem('loggedAsClient');
        } catch (cleanupError) {
          this.logger.error('Error cleaning up corrupted state:', cleanupError);
        }
        return of({ type: '[Coach] Init Error' });
      })
    );
  });

  // Restore coach impersonation state during relogin
  reloginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.reloginSuccessful),
      map(() => {
        try {
          // WIZ-2544: Improved error handling for coach state restoration during relogin
          const loggedAsClientStr = window.sessionStorage.getItem('loggedAsClient');
          if (loggedAsClientStr) {
            try {
              const client = JSON.parse(loggedAsClientStr) as CoachClientData;

              // Validate the client data before restoring
              if (!client || !client.clientId || !client.firstName || !client.lastName) {
                throw new Error('Invalid client data structure');
              }

              this.logger.info('Restoring coach impersonation state during relogin for client:', `${client.firstName} ${client.lastName}`);
              return CoachActions.logAsClient({ client });
            } catch (e) {
              // If there's an error parsing the stored data, remove it
              window.sessionStorage.removeItem('loggedAsClient');
              this.logger.error('Error restoring coach impersonation state during relogin:', e);
            }
          }
        } catch (error) {
          console.error('Unexpected error during coach state restoration:', error);
        }
        return { type: '[Coach] Relogin No Action' };
      }),
      filter(action => action.type !== '[Coach] Relogin No Action'),
      catchError(error => {
        console.error('Error in coach state restoration during relogin:', error);
        // Clean up any potentially corrupted state
        try {
          window.sessionStorage.removeItem('loggedAsClient');
        } catch (cleanupError) {
          console.error('Error cleaning up corrupted state during relogin:', cleanupError);
        }
        return of({ type: '[Coach] Relogin Error' });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private ruleService: TransactionRuleService,
    private draftService: DraftService,
    private userDataService: UserDataService,
    private store: Store,
    private router: Router,
    private sessionManager: SessionManagerService,
    private logger: LoggerService,
    private apiService: ApiService,
    private messageService: MessageService
  ) {}

  private clearCaches() {
    this.transactionService.clearCache();
    this.ruleService.clearCache();
    this.draftService.clearCache();
  }

  /**
   * Fetches fresh user data and dispatches actions to update the application state.
   *
   * This method is primarily used when impersonating a client (loggedInAsClientEffect$)
   * to load the client's data.
   *
   * Note: For exiting impersonation, we now use the enhanced getCoachData() method
   * which provides more thorough data refresh capabilities (see WIZ-2544).
   *
   * @returns An RxJS pipe that fetches user data and dispatches actions to update the store
   */
  private getData() {
    return pipe(
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      // We fetch directly the userData before dispatching the other actions because the original accounts depend on the original plan date
      switchMap(([_, yearMonth]) => {
        // Force a fresh fetch of user data to ensure we get the coach's data
        return this.userDataService.getUserData().pipe(
          map(userData => {
            this.logger.info('Fetched user data for coach account');
            return { userData, yearMonth };
          }),
          catchError(error => {
            this.logger.error('Error fetching user data for coach account:', error);
            // Return a dummy UserData object to prevent breaking the flow
            const emptyUserData: UserData = {
              email: '',
              affiliateAlias: '',
              desiredDebtFreedomAge: 0,
              dateCreated: '',
              dateUpdated: '',
              setupStepCompleted: 0,
              originalPlanYearMonth: '',
              parentAffiliateID: '',
              nameFirst: '',
              nameLast: '',
              payoutEmail: '',
              birthDate: '',
              userPictureObjectKey: '',
              retirementAge: 0,
              socialSecurityDrawAge: 0,
              pensionDrawAge: 0,
              monthlyRetirementIncome: 0,
              withdrawRate: 0,
              monthlySocialSecurity: 0,
              monthlyPension: 0,
              investmentReturnRate: 0,
              inflationRate: 0,
              currencyCode: '',
              decimalSeparator: '',
              thousandsSeparator: '',
              decimalDigits: 0,
              autoCategorization: false
            };
            return of({ userData: emptyUserData, yearMonth });
          })
        );
      }),
      switchMap(({ userData, yearMonth }) =>
        of(
          UserDataActions.getSuccessful({ userData }),
          AccountActions.getRequested({ yearMonth, autoCreate: true }),
          AccountActions.getOriginalAccountsRequested(),
          institutionsGetRequested({ autoCreate: true }),
          transactionsGetRequested({}),
          transactionRulesGetRequested()
        )
      )
    );
  }

  // getCoachData method removed as part of WIZ-2544
  // The functionality has been integrated directly into the exitImpersonationEffect$
  // for a more streamlined and maintainable implementation
}
