import { createReducer, on } from '@ngrx/store';
import { LoginActions } from '../login/login.actions';
import { logoutRequested } from '../screen.actions';
import { SubscriptionAction } from './subscription.actions';
import { SubscriptionState } from './subscription.state';

export const initialState: SubscriptionState = {
  wizefiId: '',
  haveBraintreeAccount: false,
  isInTrialPeriod: false,
  loadingStatus: 'pending',
  isActive: false,
  price: 0
};

const failureSwitcher = (state: SubscriptionState): SubscriptionState => ({ ...state, loadingStatus: 'failure' });

export const subscriptionReducer = createReducer(
  initialState,
  on(LoginActions.loginSuccessful, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'success', ...props.braintreeData })),
  // Add handler for initializeFromSession to update subscription data when restoring session
  on(
    LoginActions.initializeFromSession,
    (state, props): SubscriptionState => ({
      ...state,
      loadingStatus: 'success',
      ...props.braintreeData
    })
  ),
  on(SubscriptionAction.getDataRequested, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'loading' })),
  on(SubscriptionAction.getDataSuccessful, (state, props): SubscriptionState => ({ loadingStatus: 'success', ...props.data })),
  on(SubscriptionAction.getDataFailed, failureSwitcher),

  on(SubscriptionAction.createSubscriptionRequested, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'loading' })),
  on(SubscriptionAction.createSubscriptionSuccessful, (state, props): SubscriptionState => ({ loadingStatus: 'success', ...props.data })),
  on(SubscriptionAction.createSubscriptionFailed, failureSwitcher),

  on(SubscriptionAction.paymentMethodUpdateRequested, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'loading' })),
  on(SubscriptionAction.paymentMethodUpdateSuccessful, (state, props): SubscriptionState => ({ loadingStatus: 'success', ...props.data })),
  on(SubscriptionAction.paymentMethodUpdateFailed, failureSwitcher),

  on(SubscriptionAction.paymentMethodCreateRequested, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'loading' })),
  on(SubscriptionAction.paymentMethodCreateSuccessful, (state, props): SubscriptionState => ({ loadingStatus: 'success', ...props.data })),
  on(SubscriptionAction.paymentMethodCreateFailed, failureSwitcher),

  on(SubscriptionAction.cancelSubscriptionRequested, (state, props): SubscriptionState => ({ ...state, loadingStatus: 'loading' })),
  on(SubscriptionAction.cancelSubscriptionFailed, failureSwitcher),
  on(logoutRequested, (): SubscriptionState => initialState)
);
